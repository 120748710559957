import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { SUBSCRIBE_EMAIL_TO_NEWSLETTER } from 'graphql/customer';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import styles from './style.css';

export const Newsletter = () => {
    const t = usePhraseTranslater();
    const [subscribe, { loading, error }] = useMutation(SUBSCRIBE_EMAIL_TO_NEWSLETTER);
    const [email, setEmail] = useState('');
    const [thanks, setThanks] = useState(false);
    const handleSubmit = () => {
        subscribe({
            variables: {
                email,
            },
        }).then(() => { setThanks(true); });
    };
    return (
        <div className={styles.newsletter}>
            <form className={fStyles.form}>
                <span className={styles.title}>{t('Get Exclusive News & Offers!')}</span>
                <div className={fStyles.formField}>
                    <input
                        type="text"
                        name="email"
                        placeholder={t('email address')}
                        onChange={event => setEmail(event.currentTarget.value)}
                    />
                </div>
                {error && <div className={fStyles.error}>{t(error.message)}</div>}
                {thanks && <div className={styles.thanks}>{t('Thanks for signing up!')}</div>}
                <button type="button" className={fStyles.checkoutButton} onClick={handleSubmit} disabled={loading}>{t('Submit')}</button>
            </form>
        </div>
    );
};
