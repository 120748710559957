import React from 'react';
import { formatCurrency } from 'ui/component/checkout/util/get-currency';
import styles from '../style.css';

export const Embroidery = ({ embroideryItem, currency }) => (
    <div>
        <span className={styles.bold}>{embroideryItem.name}</span>
        &nbsp;
        <span>
            &#43;
            {formatCurrency({ currency, value: embroideryItem.price })}
        </span>
    </div>
);
