import React, { useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { ApolloProvider } from '@apollo/client';
import { GraphQlClient } from 'graphql/base';
import { StandardLoadingImage } from 'ui/component/loading-image';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { SuccessStep } from 'ui/component/checkout/steps/success/success';
import { InitCustomerSession } from './checkout-state';
import styles from './style.css';

const Success = () => {
    const [sessionLoaded, setSessionLoaded] = useState(false);
    InitCustomerSession().then(() => {
        setSessionLoaded(true);
    });
    return (
        <div data-page-type="success" className={styles.checkout}>
            <DocumentTitle title="Success!" />
            <div className={classes(styles.checkoutGrid, styles.checkoutSuccess)}>
                { sessionLoaded && (
                    <ApolloProvider client={GraphQlClient}>
                        <SuccessStep />
                    </ApolloProvider>
                )}
                {!sessionLoaded && (
                    <StandardLoadingImage />
                )}
            </div>
        </div>
    );
};

export { Success as default };
