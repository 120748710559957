import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import fStyles from 'ui/component/checkout/styles/form-style.css';
import styles from './style.css';

interface IJoinProps {
    orderId: Number;
}

export const Join: React.FC<IJoinProps> = ({ orderId }) => {
    const t = usePhraseTranslater();
    const orderViewUrl = `/sales/order/view/order_id/${orderId}`;
    const encodedUrl = btoa(orderViewUrl);
    return (
        <div className={styles.join}>
            <form className={fStyles.form}>
                <div className={fStyles.formField}>
                    <span className={styles.title}>{t('Get $25 Bonus')}</span>
                </div>
                <div className={fStyles.formField}>
                    {t('Earn rewards cash and instant discounts with each purchase!')}
                </div>
                <div className={fStyles.formField}>
                    <a
                        href={`/customer/account/create/?referer=${encodedUrl}`}
                        className={styles.joinLink}
                    >
                        {t('Join Loyalty Program')}
                    </a>
                </div>
            </form>
        </div>
    );
};
