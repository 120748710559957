import { gql, TypedDocumentNode } from '@apollo/client';

export const GET_ORDER_DETAILS: TypedDocumentNode = gql`
    query GET_ORDER_DETAILS(
        $orderNumber: String!
        $cartId: String
    ) {
        getOrderDetails(
            orderNumber:$orderNumber
            cartId:$cartId
        ) {
            entity_id
            billing_address {
              address_type
              city
              company
              country_id
              customer_address_id
              customer_id
              email
              entity_id
              fax
              firstname
              lastname
              middlename
              parent_id
              postcode
              prefix
              region
              region_code
              region_id
              street
              suffix
              telephone
            }
            shipping_address {
              address_type
              city
              company
              country_id
              customer_address_id
              customer_id
              email
              entity_id
              fax
              firstname
              lastname
              middlename
              parent_id
              postcode
              prefix
              region
              region_code
              region_id
              street
              suffix
              telephone
            }
            billing_address_id
            created_at
            customer_email
            customer_firstname
            customer_gender
            customer_group_id
            customer_lastname
            customer_middlename
            customer_prefix
            customer_suffix
            grand_total
            increment_id
            is_virtual
            items {
              description
              item_id
              name
              parent_id
              price
              price_incl_tax
              product_id
              product_type
              qty_ordered
              row_invoiced
              row_total
              row_total_incl_tax
              row_weight
              sku
              store_id
              tax_amount
              product_options {
                attributes_info {
                  label
                  option_id
                  option_value
                  value
                }
                simple_name
                simple_sku
              }
              image_url
              child {
                name
                options {
                  label
                  option_id
                  option_value
                  value
                }
                price
                sku
              }
            }
            payment {
              amount_paid
              cc_exp_month
              cc_exp_year
              cc_last_4
              cc_owner
              method
              additional_information {
                  method_title
                  cc_type
              }
            }
            shipping_amount
            shipping_description
            subtotal
            tax_amount
            total_paid
            rewards_earned
            order_currency_code
            customer_is_guest
        }
    }
`;
