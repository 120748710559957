import React from 'react';
import { formatCurrency } from 'ui/component/checkout/util/get-currency';
import { Embroidery } from './embroidery';
import styles from '../style.css';

export const OrderItem = ({ item, currency }) => (
    <div className={styles.productContainer}>
        <div className={styles.imageContainer}>
            { item.image_url !== null && (
                <img className={styles.productImage} src={item.image_url} alt={item.name} />
            )}
        </div>
        <div className={styles.productDetails}>
            <span className={styles.bold}>{item.name}</span>
            <br />
            <span className={styles.bold}>Item#:</span>
            &nbsp;
            <span>{item.sku}</span>
            <br />
            {item.product_options?.attributes_info?.map((option: any) => (
                <>
                    <span key={option.option_id}>
                        <span className={styles.bold}>{`${option.label}:`}</span>
                        &nbsp;
                        <span>{option.value}</span>
                    </span>
                    &nbsp;
                </>
            ))}
            <br />
            { item.child && (
                <div className={styles.embroideryItems}>
                    {item.child.map((embroideryItem: any) => (
                        <Embroidery key={embroideryItem.item_id} embroideryItem={embroideryItem} currency={currency} />
                    ))}
                </div>
            )}
            <div className={styles.heading}>{formatCurrency({ currency, value: item.row_total })}</div>
        </div>
    </div>
);
