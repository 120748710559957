import React from 'react';
import { formatCurrency } from 'ui/component/checkout/util/get-currency';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { OrderItem } from './order-item/order-item';
import styles from './style.css';

interface IOrderDetailsProps {
    order: any; // TODO add types to order.tsx and add to this and subcomponents
}

export const OrderDetails: React.FC<IOrderDetailsProps> = ({ order }) => {
    const { payment, items } = order;
    const shippingAddress = order.shipping_address;
    const billingAddress = order.billing_address;
    const currency = order.order_currency_code;
    const rewardsEarned = order.rewards_earned;
    const t = usePhraseTranslater();
    const orderDate = new Date(order.created_at).toLocaleDateString();
    return (
        <div className={styles.summaryContainer} data-test="order-details">
            <div className={styles.fullWidth}>
                <span className={styles.title}>{t('Thanks for your order!')}</span>
            </div>
            { (rewardsEarned > 0) && (
                <div className={classes(styles.fullWidth, styles.rewards)}>
                    <span data-rewards-earned={rewardsEarned}>{t(`You earned ${formatCurrency({ currency, value: rewardsEarned })} on your order today`)}</span>
                </div>
            )}
            <div className={styles.fullWidth}>
                <p data-order-email={order.customer_email}>{t(`Your confirmation email has been sent to ${order.customer_email}`)}</p>
            </div>
            <div className={styles.fullWidth}>
                <p>
                    <span className={styles.bold} data-order-number={order.increment_id}>
                        {t('Order')}
                        :&nbsp;
                    </span>
                    {`#${order.increment_id}`}
                    <br />
                    <span className={styles.bold} data-order-date={order.created_at}>
                        {t('Order Date')}
                        :&nbsp;
                    </span>
                    {orderDate}
                </p>
            </div>
            <div className={styles.halfWidth}>
                <p>
                    <span
                        className={styles.heading}
                        data-payment-method={payment?.additional_information?.method_code}
                        data-payment-total={order.grand_total}
                        data-payment-currency={currency}
                    >
                        Payment
                    </span>
                    <br />
                    {payment?.additional_information?.method_title}
                    <br />
                    { payment?.cc_last_4 && (
                        <>
                            {`**** **** **** ${payment?.cc_last_4}`}
                            <br />
                        </>
                    )}
                    {t(`Amount: ${formatCurrency({ currency, value: order.grand_total })}`)}
                </p>
            </div>
            { order?.shipping_description && (
                <div className={styles.halfWidth}>
                    <p>
                        <span className={styles.heading}>{t('Delivery')}</span>
                        <br />
                        {order?.shipping_description}
                        <br />
                        {formatCurrency({ currency, value: order?.shipping_amount })}
                    </p>
                </div>
            )}
            { shippingAddress && (
                <div className={styles.halfWidth}>
                    <p>
                        <span className={styles.heading}>{t('Shipping')}</span>
                        <br />
                        {shippingAddress?.company && (
                            <>
                                {shippingAddress.company}
                                <br />
                            </>
                        )}
                        {`${shippingAddress?.firstname} ${shippingAddress?.lastname}`}
                        <br />
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                        {shippingAddress.street}
                        <br />
                        {`${shippingAddress?.city}, ${shippingAddress?.region} ${shippingAddress?.postcode}`}
                        <br />
                        {shippingAddress?.country_id}
                        <br />
                        {shippingAddress?.telephone}
                    </p>
                </div>
            )}
            { billingAddress && (
                <div className={styles.halfWidth}>
                    <p>
                        <span className={styles.heading}>{t('Billing')}</span>
                        <br />
                        {`${billingAddress?.firstname} ${billingAddress?.lastname}`}
                        <br />
                        {billingAddress?.street}
                        <br />
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access */}
                        {`${billingAddress?.city}, ${billingAddress?.region} ${billingAddress?.postcode}`}
                        <br />
                        {billingAddress?.country_id}
                        <br />
                        {billingAddress?.telephone}
                    </p>
                </div>
            )}
            <div className={styles.fullWidth}>
                <span className={styles.heading}>{t('Items Ordered')}</span>
                <div className={styles.productsWrapper}>
                    {items.map(item => (
                        <OrderItem key={item.item_id} item={item} currency={currency} />
                    ))}
                </div>
            </div>
        </div>
    );
};
